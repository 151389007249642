<template>
  <div class="page-container">
    <div class="page-content fadeInDown">

      <div class="items-container">
        <form class="form-content item-masonry" v-on:submit="updatePassword">

          <div class='infos-password'>
            <img class="info-icon" src="/images/icone_info.png" alt="">
            <div>
              Le nouveau mot de passe doit :
              <ul>
                <li>comporter au moins huit caractères.</li>
                <li>contenir au moins un chiffre.</li>
                <li>contenir au moins une minuscule et une majuscule.</li>
              </ul>
            </div>
          </div>
          <input type="text" id="userName" name="username" autocomplete="username" value="" class="hidden-field">
          <div class="container-champ">
            <label class="label-champ">Nouveau mot de passe : </label>
            <input spellcheck="false" class="textfield-champ" type="password" name="password" placeholder="Nouveau mot de passe" autocomplete="new-password">
          </div>

          <div class="container-champ">
            <label class="label-champ">Confirmer nouveau mot de passe : </label>
            <input spellcheck="false" class="textfield-champ" type="password" name="repassword" placeholder="Confirmer mot de passe" autocomplete="new-password">
          </div>

          <div class='container-retour-text'>
            <div v-bind:class='retour.error === true ? "error-text" : "valid-text" ' v-if="retour.text !== null">{{retour.text}}</div>
          </div>

          <div class="buttons-container">
            <input type="submit" class="buttonPrimary" value="Valider"/>
          </div>
        </form>

        <form class="form-content item-masonry" v-on:submit="updateInfos">
          <div class="container-champ">
            <label class="label-champ">Email : </label>
            <input spellcheck="false" class="textfield-champ" type="text" name="email" placeholder="Email" autocomplete="off" :value="user.email">
          </div>

          <div class="container-champ">
            <label class="label-champ">Date de naissance : </label>
            <input spellcheck="false" class="textfield-champ" type="date" name="dateNaissance" placeholder="JJ/MM/AAAA" autocomplete="off" :value="user.dateNaissance">
          </div>

          <div class="buttons-container">
            <input type="submit" class="buttonPrimary" value="Valider"/>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style src='./profilPage.css' scoped></style>

<script>
import Api from '@/services/Api'
import router from '@/router'

export default {
  name: 'ProfilPage',
  data () {
    return {
      user: {},
      dateModel: '01-01-2000',
      retour: {error: false, text: null},
      configUrl: {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('user-token')
        }
      }
    }
  },
  methods: {
    getUserData: function () {
      let self = this

      Api().get('/users/current', this.configUrl)
        .then((response) => {
          self['user'] = response.data.user
        })
        .catch(() => {
          router.push('/')
        })
    },
    updateInfos: function (e) {
      e.preventDefault()

      let param = {
        id: this.user.id,
        email: e.target.elements.email.value,
        dateNaissance: e.target.elements.dateNaissance.value
      }

      Api().patch('/users/', param, this.configUrl)
        .then(() => {
          this.getUserData()
        })
        .catch(() => {
        })
    },
    updatePassword: function (e) {
      e.preventDefault()

      let param = {
        password: e.target.elements.password.value,
        repassword: e.target.elements.repassword.value
      }

      let self = this

      Api().patch('/users/' + this.user.id + '/password', param, this.configUrl)
        .then((response) => {
          this.getUserData()
          e.target.elements.password.value = ''
          e.target.elements.repassword.value = ''

          this.retour.error = false
          this.retour.text = response.data

          setTimeout(function () {
            self.retour.text = null
          }, 2500)
        })
        .catch((errors) => {
          this.retour.error = true
          this.retour.text = errors.response.data.errors.text

          setTimeout(function () {
            self.retour.text = null
          }, 2500)
        })
    }
  },
  mounted () {
    document.title = 'Profil'

    this.getUserData()
  }
}
</script>
