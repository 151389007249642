<template>
  <aside class="sidebar">
    <div
      class="sidebar-backdrop"
      @click="closeSidebarPanel"
      v-if="isPanelOpen"
    ></div>

    <transition name="slide">
      <nav class="sidebar-panel" v-bind:class='{ "sidebar-panel-closed" : !isPanelOpen}' >
        <ul class="sidebar-panel-nav">
          <li>
            <a href="/home" class='side-bar-title-simple' v-bind:class='{ "side-bar-title-selected" : selectedPage == "/home"}' v-on:click="redirectToPage($event, 'home')">
              <i class='icon-home'></i><span class='side-title-text'>Accueil</span>
            </a>
          </li>

          <li v-for="theme in themes" :key="theme.id">
            <a class='side-bar-title-simple' :href="getURLToPage(theme)" v-bind:class='{ "side-bar-title-selected" : selectedPage.includes("/supports/" + theme.code)}' v-on:click="redirectToPage($event, 'supports/' + theme.code)">
              <i :class='"icon-" + theme.code'></i><span class='side-title-text'>{{ theme.titre }}</span>
            </a>
          </li>

          <li>
            <a href="/listUsers" class='side-bar-title-simple' v-if="isTeacher" v-bind:class='{ "side-bar-title-selected" : selectedPage == "/listUsers" }' v-on:click='redirectToPage($event, "listUsers")'>
              <i class='icon-users'></i><span class='side-title-text'>Utilisateurs</span>
            </a>
          </li>

          <li>
            <div class='side-bar-title-simple' v-on:click='disconnect'>
              <i class='icon-logout'></i><span class='side-title-text'>Déconnexion</span>
            </div>
          </li>
        </ul>
      </nav>
    </transition>
  </aside>
</template>

<style src='./sideBar.css' scoped></style>

<script>
import Api from '@/services/Api'
import Auth from '@/mixins/Auth'
import router from '@/router'
import { mutations } from "@/services/Store.js";

export default {
  data () {
    return {
      selectedPage: '',
      isTeacher: false,
      themes: []
    }
  },
  created: async function () {
    let status = await Auth.methods.getAuthenticationStatus()
    this.isTeacher = status.typeUser === 'teacher'
    this.selectedPage = this.$route.path

    let configUrl = {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('user-token')
      }
    }    

    Api().get('/themes', configUrl)
      .then((response) => {
        this.themes = response.data.themes
      })
      .catch(() => { })
  },
  methods: {
    closeSidebarPanel: mutations.closeNav,
    redirectToPage: function (event, pageName) {
      event.preventDefault()
      if (router.currentRoute.path !== '/' + pageName) {
        router.push('/' + pageName)
      }
    },
    getURLToPage: function (theme) {
      let urlPage = '/supports/' + theme.code
      return urlPage
    },
    disconnect: function () {
      this.closeSidebarPanel()
      Auth.methods.logout()
    }
  },
  computed: {
    isPanelOpen() {
        return mutations.getNavStatus();
    },
  }, 
  watch: {
    '$route' () {
      this.selectedPage = this.$route.path
    }
  }
}
</script>
