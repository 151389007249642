<template>
  <BasicModal v-if='showedModal'>
    <template v-slot:header>Edition d'une zone de réponse</template>

    <template v-slot:body>
      <form id='form-modal' ref='form'>
        <div class='form-content'>

          <div class='container-champ'>
            <label class='label-champ'>Ordre : </label>
            <input spellcheck='false' class='textfield-champ' type='number' step='1' min='1' name='order' placeholder='1' autocomplete='off' required :value='blockToEdit.ordre'>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Hauteur : </label>
            <input spellcheck='false' class='textfield-champ' type='number' step='1' min='100' name='height' placeholder='1' autocomplete='off' required :value='blockToEdit.hauteur'>
          </div>
        </div>
      </form>
    </template>

    <template v-slot:footer>
      <div class='buttons-container'>
        <button class='buttonPrimary' @click='editBlock'>Valider</button>
        <a @click="closeModal">
          Annuler
        </a>
      </div>
    </template>

  </BasicModal>
</template>

<style src='../CreateBlockModal/createBlockModal.css' scoped></style>

<script>
import Api from '@/services/Api'
import BasicModal from '@/modals/BasicModal/BasicModal'

export default {
  name: 'EditBlockModal',
  props: {
    idBlockAreaToEdit: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      showedModal: false,
      blockToEdit: {},
      idSupport: 0
    }
  },
  components: {
    BasicModal
  },
  methods: {

    editBlock: function (e) {
      e.preventDefault()

      let data = {
        id: this.blockToEdit.id,
        height: this.$refs.form.elements.height.value,
        order: this.$refs.form.elements.order.value
      }

      let configUrl = {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('user-token')
        }
      }

      Api().post('/blocks/editBlock', data, configUrl)
        .then(() => {
          this.closeModal(null)
          this.$emit('refresh', true)
        })
        .catch(() => {
          this.closeModal(null)
        })
    },
     showModal: function (idSupport, idBlockToEdit) {
      this.idSupport = idSupport
      this.idBlockToEdit = idBlockToEdit

      let configUrl = {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('user-token')
        },
        params: {
          idSupport: this.idSupport,
          idBlock: this.idBlockToEdit
        }
      }

      Api().get('/blocks/getBlockForEdition', configUrl)
        .then((blockResponse) => {
          this.blockToEdit = blockResponse.data.block
          this.showedModal = true
        })
        .catch(() => {
        })
    },
    closeModal: function (e) {
      if (e != null) {
        e.preventDefault()
      }
      this.showedModal = false
    }
  },
  mounted () {
    this.emitter.on("openModalEditArea", params => {
      this.showModal(params.idSupport, params.idBloc)
    })
    this.emitter.on("closedModal", () => {
      this.closeModal(null);
    })
  }
}
</script>
