<template>
  <BasicModal v-if='showedModal'>
    <template v-slot:header>Mise à jour des réponses ( {{ formatTitleCourse(courseToEdit) }} )</template>
    <template v-slot:body>
      <div>
        <input type='hidden' name='idSupport' :value='this.idSupportReponses'>

        <div class="container-columns">

          <div class="panel-slots">
            <div>
              <span>Sélectionner : </span>
              <div class="container-groupes-selecters">
                <span v-for="groupe in groupes" :key="groupe.id" v-on:click="toggleGroup(groupe, users)" class="groupe-selecter" :title="groupe.titre" :style="formatBackground(groupe)">{{ groupe.titre }}</span>
              </div>
            </div>

            <div class="container-slots">
              <div class="item-slot" v-for="user in users" :key="user.id" :title="user.prenom + ' ' + user.nom" v-on:click="toggleUser(user.id, users)" :style="formatBackground(user.Groupe)" :class='{ "selected-item-slot" : user.checked == true }'>
                <input type="checkbox" v-model="user.checked">
                <label>
                  <div class="last-name">{{ formatStudentLastName(user) }}</div>
                  <div class="first-name">{{ formatStudentFirstName(user) }}</div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <div class="container-buttons">
          <button class='buttonPrimary' @click='sendReponses()'>Envoyer</button>
          <a @click='closeModal'>Fermer</a>
        </div>
      </div>
    </template>
  </BasicModal>
</template>

<style src='./supportReponsesModal.css' scoped></style>

<script>
import Api from '@/services/Api'
import BasicModal from '@/modals/BasicModal/BasicModal'

export default {
  data () {
    return {
      showedModal: false,
      courseToEdit: {},
      users: [],

      groupes: []
    }
  },
  components: {
    BasicModal
  },
  methods: {

    toggleUser: function (userId, listeUsers) {
      for (let user of listeUsers) {
        if (user.id === userId) {
          user.checked = !user.checked
        }
      }
    },
    toggleGroup: function (groupe, listeUsers) {
      for (let noSlotUser of listeUsers) {
        if (noSlotUser.Groupe.id === groupe.id) {
          noSlotUser.checked = !noSlotUser.checked
        }
      }
    },

    formatBackground: function (groupe) {
      let backgroundColor = 'grey'
      if (groupe) {
        backgroundColor = groupe.couleur
      }
      return 'border-color:' + backgroundColor + ';'
    },

    formatTitleCourse: function (course) {
      if (course.titre && course.ordre) {
        let textTitle = course.Supporttype.titre + ' : ' + course.titre

        return textTitle
      }
    },
    formatStudentLastName: function (student) {
      if (student.nom ) {
        let textStudent = student.nom.replace("-", " ").substring(0, 12)
          
        return textStudent;
      }
    },
    formatStudentFirstName: function (student) {
      if (student.prenom) {
        let textStudent = student.prenom.replace("-", " ").substring(0, 12)
          
        return textStudent;
      }
    },
    refreshCourseSlots: function () {
      let configUrl = {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('user-token')
        },
        params: {
          idSupport: this.courseToEdit.id
        }
      }

      Api().get('/users', configUrl)
        .then((response) => {
          for (let user of response.data.users) {
            user.checked = false
          }
          this.users = response.data.users
        })
        .catch(() => {
        })

      Api().get('/users/groups', configUrl)
        .then((response) => {
          this.groupes = response.data.groups
        })
        .catch(() => {
        })
    },
    showModal: function (idSupportReponses) {
      this.idSupportReponses = idSupportReponses
      let configUrl = {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('user-token')
        },
        params: {
          idSupport: idSupportReponses
        }
      }

      Api().get('/supports/getSupportForEdition', configUrl)
        .then((response) => {
          this.courseToEdit = response.data.support
          this.refreshCourseSlots()

          this.showedModal = true
        })
        .catch(() => {
        })
    },
    sendReponses: function () {
      let configUrl = {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('user-token')
        }
      }
      let usersToSendResponses = []
      for (let user of this.users) {
        if (user.checked) {
          usersToSendResponses.push(user.id)
        }
      }

      let data = {
        idSupport: this.idSupportReponses,
        idUsers: usersToSendResponses
      }

      Api().post('/supports/sendReponsesSupportToUsers', data, configUrl)
        .then(() => {
          alert('Réponses envoyées')
        })
        .catch(() => {
        })
    },
    closeModal: function (e) {
      if (e != null) {
        e.preventDefault()
      }
      this.showedModal = false
    }
  },
  mounted () {
    this.emitter.on("openModalSupportReponses", params => {
      this.showModal(params.idSupport)
    })
    this.emitter.on("closedModal", () => {
      this.closeModal(null);
    })
  }
}
</script>
