<template>
  <BasicModal v-if='showedModal'>
   <template v-slot:header>Création d'une zone de réponse</template>

    <template v-slot:body>
      <div>
        <form id='form-modal' ref='form'>
          <div class='form-content'>

            <div class='container-champ'>
              <label class='label-champ'>Ordre : </label>
              <input spellcheck='false' class='textfield-champ' type='number' step='1' min='1' :max='blocksMax' name='order' placeholder='1' autocomplete='off' required v-model='currentBlockValue'>
            </div>

            <div class='container-champ'>
              <label class='label-champ'>Hauteur : </label>
              <input spellcheck='false' class='textfield-champ' type='number' step='1' min='100' name='height' placeholder='1' autocomplete='off' required value='100'>
            </div>

            <div class='container-champ'>
              <label class='label-champ'>Zone HTML : </label>
              <input class='textfield-champ' type='checkbox' name='htmlblock'>
            </div>

          </div>
        </form>
      </div>
    </template>

    <template v-slot:footer>
      <div>
        <div class='buttons-container'>
          <button class='buttonPrimary' @click='createBlock'>Valider</button>
          <a @click="closeModal">
            Annuler
          </a>
        </div>
      </div>
    </template>
  </BasicModal>
</template>

<style src='../CreateBlockModal/createBlockModal.css' scoped></style>

<script>

import Api from '@/services/Api'
import BasicModal from '@/modals/BasicModal/BasicModal'

export default {
  name: 'CreateAreaModal',
  data () {
    return {
      showedModal: false,
      blocksMax: 0,
      currentBlockValue: 0,
      idSupport: 0
    }
  },
  components: {
    BasicModal
  },
  methods: {
    createBlock: async function (e) {
      e.preventDefault()

      let titre = 'area'
      if (this.$refs.form.elements.htmlblock.checked) {
        titre = 'areahtml'
      }
      let configGetUrl = {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('user-token')
        },
        params: {
          titre: titre
        }
      }

      let reponseBloctype = await Api().get('/blocks/getBlocktypeByTitle', configGetUrl)

      let data = {
        content: '',
        height: this.$refs.form.elements.height.value,
        order: this.$refs.form.elements.order.value,
        idSupport: this.idSupport,
        idBlocktype: reponseBloctype.data.bloctype.id
      }

      let configUrl = {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('user-token')
        }
      }

      Api().post('/blocks', data, configUrl)
        .then(() => {
          this.closeModal(null)
          this.$emit('refresh', true)
        })
        .catch(() => {
          this.closeModal(null)
        })
    },
    closeModal: function (e) {
      if (e != null) {
        e.preventDefault()
      }
      this.content = ''
      this.$emit('closedCreateModal', true)
      this.showedModal = false
    },
    showModal: function (idSupport) {
      this.idSupport = idSupport
      let configUrl = {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('user-token')
        },
        params: {
          idSupport: this.idSupport
        }
      }

      Api().get('/blocks/getBlocksCount', configUrl)
        .then((response) => {
          this.blocksMax = response.data.blockscount + 1
          this.currentBlockValue = response.data.blockscount + 1
          this.showedModal = true
        })
        .catch(() => {
        })
    }
  },
  mounted () {
    this.emitter.on("openModalCreateArea", params => {
      this.showModal(params.idSupport)
    })
    this.emitter.on("closedModal", () => {
      this.closeModal(null);
    })
  }
}
</script>
