<template>
  <BasicModal v-if='showedModal'>
    <template v-slot:header>
      Création d'un titre
    </template>

    <template v-slot:body>
      <form id='form-modal' ref='form'>
        <div class='form-content'>
          <div class='container-champ'>
            <label class='label-champ'>Libellé : </label>
            <input type="text" class='textfield-champ' name='content'>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Ordre : </label>
            <input spellcheck='false' class='textfield-champ' type='number' step='1' min='1' :max='blocksMax' name='order' placeholder='1' autocomplete='off' required v-model='currentBlockValue'>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Accès limité </label>
            <input type='checkbox' name='limitedAccess'>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Niveau de titre : </label>
            <div class="formfield-select--container">
              <select name='idBlocktype'>
                <option v-for="blocktype in blocktypes" :key="blocktype.id" :value='blocktype.id'>
                  {{ blocktype.titre }}
                </option>
              </select>
            </div>
          </div>

        </div>
      </form>
    </template>

    <template v-slot:footer>
      <div class='buttons-container'>
        <button class='buttonPrimary' @click='createBlock'>Valider</button>
        <a @click="closeModal">
          Annuler
        </a>
      </div>
    </template>
  </BasicModal>
</template>

<style src='./createBlockModal.css' scoped></style>

<script>

import Api from '@/services/Api'
import BasicModal from '@/modals/BasicModal/BasicModal'

export default {
  name: 'CreateBlockModal',
  data () {
    return {
      idSupport: 0,
      content: '',
      blocksMax: 0,
      currentBlockValue: 0,

      showedModal: false,
      blocktypes: []
    }
  },
  components: {
    BasicModal
  },
  methods: {
    createBlock: function (e) {
      e.preventDefault()

      let data = {
        content: this.$refs.form.elements.content.value,
        order: this.$refs.form.elements.order.value,
        limitedAccess: this.$refs.form.elements.limitedAccess.checked,
        idBlocktype: this.$refs.form.elements.idBlocktype.value,
        idSupport: this.idSupport
      }

      let configUrl = {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('user-token')
        }
      }

      Api().post('/blocks', data, configUrl)
        .then(() => {
          this.closeModal(null)
          this.$emit('refresh', true)
        })
        .catch(() => {
          this.closeModal(null)
        })
    },
    closeModal: function (e) {
      if (e != null) {
        e.preventDefault()
      }

      this.$emit('closedModal', true)
      this.showedModal = false
    },
    showModal: async function (idSupport) {
      this.idSupport = idSupport
      let configUrl = {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('user-token')
        },
        params: {
          idSupport: this.idSupport
        }
      }

      let responseBlocktypes = await Api().get('/blocks/getBlocktypesTitre', configUrl)
      this.blocktypes = responseBlocktypes.data.bloctypes

      Api().get('/blocks/getBlocksCount', configUrl)
        .then((response) => {
          this.blocksMax = response.data.blockscount + 1
          this.currentBlockValue = response.data.blockscount + 1
          this.showedModal = true
        })
        .catch(() => {
        })
    }
  },
  mounted () {
    this.emitter.on("openModalCreateTitre", params => {
      this.showModal(params.idSupport)
    })
    this.emitter.on("closedModal", () => {
      this.closeModal(null);
    })
  }
}
</script>
