<template>
  <div class='container-box'>
    <div class='container-areas' ref='containerAreas'>
      <div class='areahtml-input-container'>
        <div class='title-area'>HTML</div>
        <textarea spellcheck='false' disabled class='areahtml-input' ref="areaHTML"></textarea>
      </div>
      <div class='areahtml-input-container'>
        <div class='title-area'>CSS</div>
        <textarea spellcheck='false' v-on:keydown='tabulationFormat' class='areahtml-input' v-bind:disabled="currentBlock.defaultCSSValue ? 'disabled' : null" @blur='saveReponse($event, currentBlock.id)' @input='updateBlockSize($event)' ref="areaCSS"></textarea>
      </div>
    </div>
    <div class='areahtml-result-container' ref='containerResult'>
      <iframe class='areahtml-result' ref="frameCSS"></iframe>
    </div>
  </div>
</template>

<style src='./areaCss.css' scoped></style>

<script>
import router from '@/router'
import Api from '@/services/Api'

export default {
  name: 'AreaCss',
  props: {
    currentBlock: {}
  },
  data () {
    return {
    }
  },
  methods: {
    updateFrameContent: function (CSSUserReponse) {
      let ifrm = this.$refs['frameCSS'].contentDocument
      let HTMLText = this.currentBlock.contenu
      let CSSText = 'body {color: grey; margin: 5px;}'
      if (this.currentBlock.contenuCSS !== null) {
        CSSText += this.currentBlock.contenuCSS
      }
      if (this.currentBlock.defaultCSSValue) {
        CSSText += this.currentBlock.defaultCSSValue
      } else {
        CSSText += CSSUserReponse
      }

      let frameContent = '<!DOCTYPE html><html><head></head><body></body></html>'
      frameContent += HTMLText
      frameContent += '<style>' + CSSText + '</style>'
  
      ifrm.write('')
      ifrm.open()
      ifrm.write(frameContent)
      ifrm.close()

    },
    resizeBlock: function () {
      this.$nextTick(() => {
        let areaHTML = this.$refs['areaHTML']
        let areaCSS = this.$refs['areaCSS']
        let frameCSS = this.$refs['frameCSS']
        areaHTML.style.height = 0
        areaCSS.style.height = 0
        frameCSS.style.height = 0

        let newHeight = areaHTML.scrollHeight + areaCSS.scrollHeight
        if (frameCSS.contentDocument.body.scrollHeight > newHeight) {
          newHeight = frameCSS.contentDocument.body.scrollHeight
        }

        areaHTML.style.height = areaHTML.scrollHeight + "px"
        let areaCSSHeight = newHeight - areaHTML.scrollHeight;
        areaCSS.style.height = areaCSSHeight + "px"
        this.$refs['frameCSS'].style.height = newHeight + "px"
      })
    },
    updateBlockSize: function (event) {
      this.updateFrameContent(event.target.value)
      this.resizeBlock()
    },
    redirectToPage: function (event, pageName) {
      event.preventDefault()

      if (router.currentRoute.path !== '/' + pageName) {
        router.push('/' + pageName)
      }
    },
    getContent: function (block) {
      if (block.contenu) {
        return block.contenu
      }
    },
    getReponse: function (block) {
      let contentReponse = null
      if (block.defaultCSSValue) {
        contentReponse = block.defaultCSSValue
      }
      else if (block.Reponses[0] && block.Reponses[0].contenu) {
        contentReponse =  block.Reponses[0].contenu
      }
      return contentReponse
    },
    saveReponse: function (event, idBlock) {
      let configUrl = {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('user-token')
        }
      }
      let data = {
        idBlock: idBlock,
        content: event.target.value
      }

      Api().post('/blocks/saveReponse', data, configUrl)
        .then(() => {
          this.$emit('refresh', true)

          this.updateFrameContent(event.target.value)
        })
        .catch(() => {
        })
    }
  },
  mounted () {
    let cssContent = this.getReponse(this.currentBlock)

    this.$refs['areaHTML'].value = this.currentBlock.contenu
    this.$refs['areaCSS'].value = cssContent
    
    this.updateFrameContent(cssContent)
    this.resizeBlock()
  }
}
</script>
