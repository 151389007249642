<template>
  <BasicModal v-if='showedModal'>
    <template v-slot:header>Edition d'un code</template>

    <template v-slot:body>
      <form id='form-modal' ref='form'>
        <div class='form-content'>
          <label class='label-champ'>Contenu : </label>
          <textarea class='textarea-champ' name='content' spellcheck='false' :value='blockToEdit.contenu'></textarea>

          <div class='container-champ'>
            <label class='label-champ'>Ordre : </label>
            <input spellcheck='false' class='textfield-champ' type='number' step='1' min='1' :max='blocksMax' name='order' placeholder='1' autocomplete='off' :value='blockToEdit.ordre'>
          </div>

          <div class='container-champ'>
            <label class='label-champ'>Accès limité </label>
            <input class='textfield-champ' type='checkbox' name='limitedAccess'>
          </div>
        </div>
      </form>
    </template>

    <template v-slot:footer>
      <div class='buttons-container'>
        <button class='buttonPrimary' @click='editBlock'>Valider</button>
        <a @click="closeModal">
          Annuler
        </a>
      </div>
    </template>
  </BasicModal>
</template>

<style src='../CreateCodeModal/createCodeModal.css' scoped></style>

<script>

import Api from '@/services/Api'
import BasicModal from '@/modals/BasicModal/BasicModal'

export default {
  name: 'EditBlockModal',
  data () {
    return {
      blockToEdit: {},
      idSupport: 0,
      content: '',
      blocksMax: 0,
      currentBlockValue: 0,

      showedModal: false,
      blocktypes: []
    }
  },
  components: {
    BasicModal
  },
  methods: {
    editBlock: function (e) {
      e.preventDefault()

      let data = {
        id: this.blockToEdit.id,
        content: this.$refs.form.elements.content.value,
        order: this.$refs.form.elements.order.value,
        idBlocktype: 11
      }

      let configUrl = {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('user-token')
        }
      }

      Api().post('/blocks/editBlock', data, configUrl)
        .then(() => {
          this.closeModal(null)
          this.$emit('refresh', true)
        })
        .catch(() => {
          this.closeModal(null)
        })
    },
    closeModal: function (e) {
      if (e != null) {
        e.preventDefault()
      }

      this.showedModal = false
    },
    showModal: async function (idSupport, idBlockToEdit) {
      this.idSupport = idSupport
      this.idBlockToEdit = idBlockToEdit

      let configUrl = {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('user-token')
        },
        params: {
          idSupport: this.idSupport,
          idBlock: this.idBlockToEdit
        }
      }

      let responseBlocktypes = await Api().get('/blocks/getBlocktypesTitre', configUrl)
      this.blocktypes = responseBlocktypes.data.bloctypes

      let responseBlocksCount = await Api().get('/blocks/getBlocksCount', configUrl)
      this.blocksMax = responseBlocksCount.data.blockscount + 1
      this.currentBlockValue = responseBlocksCount.data.blockscount + 1

      Api().get('/blocks/getBlockForEdition', configUrl)
        .then((blockResponse) => {
          Api().get('/blocks/getBlocktypes', configUrl)
            .then(() => {
              this.blockToEdit = blockResponse.data.block
              this.showedModal = true
            })
            .catch(() => {
            })
        })
        .catch(() => {
        })
    }
  },
  mounted () {
    this.emitter.on("openModalEditCode", params => {
      this.showModal(params.idSupport, params.idBloc)
    })
    this.emitter.on("closedModal", () => {
      this.closeModal(null);
    })
  }
}
</script>
