import Api from '@/services/Api'
import router from '@/router'

export function getAuthenticationStatus () {
  let status = {}
  status.authenticated = false
  status.typeUser = 'student'
  status.validated = false

  if (localStorage.getItem('user-token')) {
    let configUrl = {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('user-token')
      }
    }
    return Api().get('/users/current', configUrl)
      .then(function (response) {
        status.authenticated = true
        status.idUser = response.data.user.id
        status.typeUser = response.data.user.typeUtilisateur
        status.validated = response.data.user.validated
        status.authorized = response.data.user.authorized
        return status
      })
      .catch(function () {
        return status
      })
  } else {
    return status
  }
}

export function logout () {
  localStorage.removeItem('user-token')
  router.push('/')
}

export function login (data) {
  return Api().post('/users/login', data)
    .then((response) => {
      localStorage.setItem('user-token', response.data.user.token)
    })
    .catch((errors) => {
      localStorage.removeItem('user-token')
      throw errors
    })
}

export default {
  methods: {
    getAuthenticationStatus,
    login,
    logout
  }
}
