<template>
  <BasicModal v-if="showedModal">
    <template v-slot:header>Nouveau mot de passe</template>
    <template v-slot:body>
      <form ref="form">
        <div class="infos-password">
          <div>
            Le nouveau mot de passe doit :
            <ul>
              <li>comporter au moins huit caractères.</li>
              <li>contenir au moins un chiffre.</li>
              <li>contenir au moins une minuscule et une majuscule.</li>
            </ul>
          </div>
        </div>
        <div class="container-password">
          <label>Mot de passe :</label>
          <input
            type="password"
            id="password"
            name="password"
            autocomplete="new-password"
          />
        </div>
        <div class="container-password">
          <label>Confirmer mot de passe :</label>
          <input
            type="password"
            id="repassword"
            name="repassword"
            autocomplete="new-password"
          />
        </div>

        <span class="label-error">{{ message }}</span>
      </form>
    </template>
    <template v-slot:footer>
      <div class="buttons-container">
        <button v-on:click="updatePassword()" class="buttonPrimary">OK</button>
        <a @click="closeModal()"> Annuler </a>
      </div>
    </template>
  </BasicModal>
</template>

<style src='../BasicModal/basicModal.css' scoped></style>
<style src='./passwordModal.css' scoped></style>

<script>
import Api from "@/services/Api";
import BasicModal from "@/modals/BasicModal/BasicModal";

export default {
  name: "PasswordModal",
  data() {
    return {
      idUser: 0,
      showedModal: false,
      message: "",
      idTimer: "",
    };
  },
  components: {
    BasicModal,
  },
  methods: {
    updatePassword: function () {
      let param = {
        password: this.$refs.form.elements.password.value,
        repassword: this.$refs.form.elements.repassword.value,
      };

      let configUrl = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("user-token"),
        },
      };

      Api()
        .patch("/users/" + this.idUser + "/password", param, configUrl)
        .then(() => {
          this.closeModal();
        })
        .catch((errors) => {
          let self = this;

          self.message = errors.response.data.errors.text;
          self.idTimer = setTimeout(function () {
            self.message = "";
          }, 1500);
        });
    },
    showModal: function (idUser) {
      this.idUser = idUser
      this.showedModal = true;
    },
    closeModal: function () {
      this.showedModal = false;
      this.message = "";
      clearTimeout(this.idTimer);
    },
  },
  mounted() {
    this.emitter.on("openModalPassword", params => {
      this.showModal(params.idUser)
    })
    this.emitter.on("closedModal", () => {
      this.closeModal(null);
    })
  }
}
</script>
